import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`instagram`}</h2>
    <iframe src="//instagram.com/p/CMCqW5yAamf/embed/" width="315" height="375" frameBorder="0" scrolling="no" allowtransparency="true"></iframe>
    <br />
    <br />
    <iframe src="//instagram.com/p/CLO8U0CgIRb/embed/" width="315" height="375" frameBorder="0" scrolling="no" allowtransparency="true"></iframe>
    <br />
    <br />
    <iframe src="//instagram.com/p/CLmHLl5A8bi/embed/" width="315" height="490" frameBorder="0" scrolling="no" allowtransparency="true"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      