import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`masalifetokyo`}</h2>
    <p>{`Work in Progress ...`}</p>
    <p>{`レシピは以下のリンクから`}</p>
    <p><a parentName="p" {...{
        "href": "./recipes/"
      }}>{`masalife recipes web`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      